import React, { useState } from "react"
import SEO from "../components/seo"
import Layout from "../components/layout/layout"
import "./nuestros-servicios.scss"
import { ServiceCard } from "../components/service-card/service-card"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"

import {
  faFax,
  faPhoneSquareAlt,
  faFileArchive,
  faFileInvoice,
  faFile,
} from "@fortawesome/free-solid-svg-icons"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { TickMark } from "../components/tick-mark/tick-mark"
import { Link } from "gatsby"
import { StringyWindupTitle } from "../components/stringy-windup/stringy-windup-title"
import Fade from "react-reveal/Fade"
import config from "react-reveal/globals"
import { StringyWindup } from "../components/stringy-windup/stringy-windup"
import Note from "../components/note/note"
import { SloganFlipper } from "../components/slogan/slogan-flipper"
import { Banner } from "../components/banner/banner"
import { GlobalStateProvider } from "../utils/globalStateProvider"
import { FullScreenModal } from "../components/full-screen-modal/full-screen-modal"

config({ ssrFadeout: true })

export default function NuestrosServicios() {
  const [status, setStatus] = useState("")

  return (
    <GlobalStateProvider>
      <React.Fragment>
        <SEO title="Nuestros servicios" />
        <Layout pageId="our-services">
          <div className="our-services-container">
            <h1>Nuestros servicios</h1>

            <StringyWindup
              text="negligenciasdeabogados.net te
                        pone en contacto con expertos para reclamar por negligencias de abogados"
              callbackFn={() => setStatus("step-1")}
            ></StringyWindup>
            <Fade left duration={2000} when={status === "step-1"}>
              <ul className="cards grid grid--desktop-3cols grid--smartphone-1cols">
                <li key="cuestionario">
                  <ServiceCard
                    title="Autovaloración"
                    key="cuestionario-autovaloracion"
                    featuredImage={
                      <FontAwesomeIcon
                        icon={faFileInvoice}
                        color="#fcc831"
                        size="2x"
                      />
                    }
                    featureList={[
                      "<strong>Online</strong> y <strong>gratis!</strong>",
                      "<strong>Sin datos personales</strong>",
                      "Máximo <strong>10 preguntas</strong>",
                    ]}
                    ctaLink="/cuestionario-autovaloracion"
                    isFree={true}
                    showModalNoAvailable={false}
                  />
                </li>

                {/* <li key="cuestionario">
                                <ServiceCard
                                    title="Cuéntanos"
                                    key="cuestionario-autovaloracion"
                                    featuredImage={
                                        <FontAwesomeIcon
                                            icon={faFileInvoice}
                                            color="#fcc831"
                                            size="2x"
                                        />
                                    }
                                    featureList={[
                                        "<strong>Online</strong> y <strong>gratis!</strong>",
                                        "Orientación inicial",
                                        "Casos reales"
                                    ]}
                                    ctaLink="/cuestionario-autovaloracion"
                                    isFree={true}
                                />
                            </li> */}
                <li key="consulta-experto">
                  <ServiceCard
                    title="Te llamamos 30"
                    key="consulta-experto"
                    featuredImage={
                      <FontAwesomeIcon
                        icon={faWhatsapp}
                        color="#fcc831"
                        size="2x"
                      />
                    }
                    featureList={[
                      "Servicio <strong>premium</strong>",
                      "Un <strong>experto</strong> te llamará por tfno/whatsapp",
                      // "<strong>30 minutos</strong> por <strong class='price'>30€</strong>",
                    ]}
                    ctaLink="/consulta-experto"
                  />
                </li>
                <li key="contratar-dictamen">
                  <ServiceCard
                    title="Dictamen"
                    key="contratar-dictamen"
                    featuredImage={
                      <FontAwesomeIcon
                        icon={faFileArchive}
                        color="#fcc831"
                        size="2x"
                      />
                    }
                    featureList={[
                      "Documento <strong>premium</strong>",
                      "<strong>Validez jurídica</strong>",
                      // "<strong class='price'>30€</strong> (consulta) + <strong class='price'>140€</strong>",
                    ]}
                    ctaLink="/encargo-dictamen"
                  />
                </li>
              </ul>
            </Fade>

            <Fade left duration={2500} when={status === "step-1"}>
              <Banner></Banner>
            </Fade>

            <Fade right duration={3000} when={status === "step-1"}>
              <section className="notes">
                <Note>Máxima confidencialidad en los datos</Note>
                <br></br>
                {/* <Note>
                                Te recomendamos, si aún no lo has hecho,{" "}
                                <Link to="/cuestionario-autovaloracion/">
                                    <strong>
                                        completar el cuestionario de
                                        autovaloración
                                    </strong>
                                </Link>{" "}
                                para proporcionar más información a nuestros
                                expertos y poder prestar un servicio de mayor
                                calidad
                            </Note> */}
              </section>
              {/* <section className="panel note">
                            <FontAwesomeIcon icon={faInfoCircle} size="2x"/>
                            <ul>
                                <li>Máxima confidencialidad en los datos</li>
                                <li>
                                    Te recomendamos, si aún no lo has hecho,{" "}
                                    <Link to="/cuestionario-autovaloracion/">
                                        <strong>
                                            completar el cuestionario de
                                            autovaloración
                                        </strong>
                                    </Link>{" "}
                                    para proporcionar más información a nuestros
                                    expertos y poder prestar un servicio de
                                    mayor calidad
                                </li>
                            </ul>
                        </section> */}
            </Fade>
          </div>
          <FullScreenModal />
        </Layout>
      </React.Fragment>
    </GlobalStateProvider>
  )
}

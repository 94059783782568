import React from "React";
import Img from "gatsby-image";
import { navigate, navigateTo, useStaticQuery } from "gatsby";

import { faQuestion, faRes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./banner.scss";
import { graphql } from "gatsby";

export const Banner = ({ gqlImage }) => {
    const queryImagesResult = useStaticQuery(graphql`
        query {
            bannerImgResult: allImageSharp(
                filter: {
                    fixed: {
                        originalName: { in: ["question-answer-icon.png"] }
                    }
                }
            ) {
                nodes {
                    fixed(width: 100) {
                        originalName
                        ...GatsbyImageSharpFixed
                    }
                    fluid(maxWidth: 100) {
                        originalName
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    const { bannerImgResult } = queryImagesResult;

    // const imagesGql = slidesImages.map((item) => {
    //     return bannerImgResult.nodes.find((n) => n.fixed.originalName === item);
    // });
    return (
        <div
            className="banner-container"
            onClick={() => {
                navigate("/casos-reales/");
            }}
        >
            {/* <FontAwesomeIcon icon={faQuestion} color="black" size="2x" /> */}
            <Img
                className="icon"
                fixed={bannerImgResult.nodes[0].fixed}
                alt="pregunta-respuesta-casos-reales"
            />
            {/* <Img
                className="img-mobile"
                fixed={imagesGql[0].fixed}
                alt="pregunta-respuesta-casos-reales"
            /> */}
            <div className="center">
                <h2 className="title">Cuéntanos tu caso</h2>
                <h3 className="subtitle">experto te responde</h3>
            </div>
            {/* <div className="separator">&nbsp;</div> */}
            <div className="right">
                <h2 className="title">Consulta casos reales</h2>
                <h3 className="subtitle">divorcios, despidos, etc</h3>
            </div>
        </div>
    );
};
